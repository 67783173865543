import './src/css/global.css';
import React from "react"
import { CartProvider } from "react-use-cart";
import { CartContextProvider } from './src/components/cart_context';

export const wrapRootElement = ({ element }) => 
(
  <CartProvider>
    <CartContextProvider>
      {element}
    </CartContextProvider>
  </CartProvider>
)
