import React, { useState, useEffect, createContext } from 'react';
import Client from 'shopify-buy';

const client = Client.buildClient({
  domain: `ledandcollared.myshopify.com`,
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_STORE_FRONT_ACCESS_TOKEN,
});

const defaultState = {
  cart: {},
};

const CartContext = createContext(defaultState);

export default CartContext;

export function CartContextProvider({ children }) {

  const [checkout, setCheckout] = useState(
    JSON.parse(
      typeof window !== 'undefined' ? localStorage.getItem('ledandcollared_checkout') : null
    )
  );
  
  const [successfulOrder, setSuccessfulOrder] = useState(null);
  const checkoutId = checkout?.id;

  useEffect(() => {
    const getCheckout = async () => {
      if (checkoutId && typeof window !== 'undefined') {
        const fetchedCheckout = await client.checkout.fetch(checkoutId);
        // Add a check here to ensure fetchedCheckout is not null or undefined
        if (fetchedCheckout && fetchedCheckout.completedAt) {
          localStorage.removeItem('ledandcollared_checkout');
          setCheckout(null);
          setSuccessfulOrder(fetchedCheckout);
        } else if (fetchedCheckout) {
          setCheckout(fetchedCheckout);
          localStorage.setItem('ledandcollared_checkout', JSON.stringify(fetchedCheckout));
        } else {
          // Handle the case where fetchedCheckout is null or undefined
          console.error('Failed to fetch checkout, it might be expired or invalid.');
          // Clear the checkout cart from local storage
          localStorage.removeItem('ledandcollared_checkout');
          // Since there's no valid checkout, setCheckout to null or a new checkout object as needed
          setCheckout(null);
          // Optionally, you might want to reset any related state, such as successfulOrder
          setSuccessfulOrder(null);
        }        
      }
    };    
    getCheckout();
    // console.log('checkout: ', checkout);
  }, [setCheckout, setSuccessfulOrder, checkoutId]);

  /*
  async function getProductById(productId) {
    const product = await client.product.fetch(productId);
    return product;
  }
  */

  async function getProductById(productId) {
    try {
      const product = await client.product.fetch(productId);
      return product;

    } catch (error) {
      let data = new FormData();
      data.append('type', 'getProductById(productId)');
      data.append('error', error[0].message);
      data.append('product', productId);

      fetch('/api/errors', {
        method: 'POST',
        body: data,
      });
      return null;
    }
  }

  // in cart
  const updateCartLineItem = async ({ variantId, quantity }) => 
  {
    let newCheckout = checkout || (await client.checkout.create());
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    );
    newCheckout = await client.checkout.updateLineItems(newCheckout.id, [{
        id: lineItemVariant.id,
        quantity: quantity,
      },
    ]);
    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('ledandcollared_checkout', JSON.stringify(newCheckout));
    }
    setAddToCart(true)
    setTimeout(() => {
      addCart(false);
    }, 1000);
  };

  // product
  const updateLineItem = async ({variantId, quantity, customSelections}) => 
  {
    let newCheckout = checkout || (await client.checkout.create());
    const lineItemVariant = newCheckout.lineItems?.find(
      lineItem => lineItem.variant.id === variantId
    );
    newCheckout = await client.checkout.addLineItems(newCheckout.id, [{
        variantId,
        quantity,
        customAttributes: customSelections
      },
    ]);
    
    /*
    if (lineItemVariant) {
      const newQuantity = lineItemVariant.quantity + quantity;
      newCheckout = await client.checkout.updateLineItems(newCheckout.id, [{
          id: lineItemVariant.id,
          quantity: newQuantity,
        },
      ]);
    } else {
        newCheckout = await client.checkout.addLineItems(newCheckout.id, [{
          variantId,
          quantity,
          customAttributes: customSelections
        },
      ]);
    }
    */
    setCheckout(newCheckout);
    setSuccessfulOrder(null);
    if (typeof window !== 'undefined') {
      localStorage.setItem('ledandcollared_checkout', JSON.stringify(newCheckout));
    }
    setAddToCart(true)
    setTimeout(() => {
      addCart(false);
    }, 1000);
  };

  const removeLineItem = async lineItemId => {
    const newCheckout = await client.checkout.removeLineItems(checkout.id, [
      lineItemId,
    ]);
    setCheckout(newCheckout);
  };

  const dismissSuccessfulOrder = () => {
    setSuccessfulOrder(null);
  };

  const [addToCart, setAddToCart] = useState(false)
  const addCart = (e) => {
    setAddToCart(e);
  };

  const [cartOpen, setCartOpen] = useState(false)

  return (
    <CartContext.Provider
      value={{
        updateCartLineItem,
        cartOpen,
        setCartOpen,
        checkout,
        updateLineItem,
        removeLineItem,
        getProductById,
        successfulOrder,
        dismissSuccessfulOrder,
        addToCart,
        addCart
      }}
    >
      {children}
    </CartContext.Provider>
  );
}